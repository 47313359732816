import { useMemo } from "react";

import { Brand } from "@efarmz/efarmz-domain-typescript";

import BrandListSlider from "@/components/organisms/BrandListSlider";
import BrandListSliderElastic from "@/components/organisms/BrandListSliderElastic";

type Section = {
  brands?: Brand[];
  source: `DEFAULT` | `CUSTOM`;
};

type SanityBrandSliderSectionProps = {
  section: Section;
  className?: string;
  legacy?: boolean;
};

const SanityBrandSliderSection = ({
  section,
  className,
  legacy = false,
}: SanityBrandSliderSectionProps) => {
  const Slider = useMemo(() => {
    if (section?.source === `CUSTOM` && section?.brands?.length) {
      return <BrandListSlider brands={section?.brands} className={className} />;
    }

    return (
      <BrandListSliderElastic
        settings={{
          search_attributes: [`name`],
          result_attributes: [`id`, `name`, `description`, `image`],
          facet_attributes: [],
          filter_attributes: [],

          /*
  search_attributes: [`name`, `email`],
  result_attributes: [`id`, `name`, `email`],
  highlight_attributes: [`name`],
  */
        }}
      />
    );
  }, []);
  return Slider;
};

export default SanityBrandSliderSection;
