import NextLink from "next/link";
import { useLocale } from "next-intl";

import SanityImage from "@/components/atoms/SanityImage";

import SanityButton from "../SanityButton";

type Section = {
  title?: string;
  subtitle?: string;
  buttons?: any[];
  image?: any;
};

type SanityHeroSectionProps = {
  section: Section;
  legacy?: boolean;
};

const SanityHeroSection = ({
  section,
  legacy = false,
}: SanityHeroSectionProps) => {
  const Link = legacy ? `a` : NextLink;
  const locale = useLocale();

  const Buttons = section?.buttons?.length
    ? section?.buttons.map((button, i) => (
        <SanityButton {...button} key={i.toString()} size="xl" />
      ))
    : [];
  return (
    <div className="bg-white">
      <div className="relative">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
            <div className="relative px-6 py-32 sm:py-40 lg:px-8 lg:py-56 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                {/*
                <div className="hidden sm:mb-10 sm:flex">
                  <div className="bg-menthe-100 relative rounded-md px-3 py-1 text-sm leading-6 text-vert-500 hover:ring-gray-900/20">
                    Anim aute id magna aliqua ad ad non deserunt sunt.{` `}
                    <a
                      href="#"
                      className="whitespace-nowrap font-semibold text-choux-500"
                    >
                      <span className="absolute inset-0" aria-hidden="true" />
                      Read more <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
  </div>*/}
                <h1 className="text-2xl font-bold tracking-tight text-vert-500 sm:text-5xl">
                  {section?.title}
                </h1>
                <p className="mt-6 text-lg leading-8 text-menthe-500 font-semibold">
                  {section?.subtitle}
                </p>
                <div className="mt-10 flex items-center gap-x-6">{Buttons}</div>
              </div>
            </div>
          </div>
        </div>
        {section?.image && (
          <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <SanityImage
              className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
              image={section.image}
              alt=""
            />
            {/*
            
          <svg
              className="absolute inset-y-0 right-8 hidden h-full w-80 -left-32 transform fill-white lg:block"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>
        */}
          </div>
        )}
      </div>
    </div>
  );
};

export default SanityHeroSection;
