import Feature from "@/types/Feature";

import useAuth from "./useAuth";

export const FEATURE_ACCESS: Record<Feature, number[]> = {
  [Feature.BOX_SUBSCRIPTION_NEXT]: [69741],
  [Feature.DLW_NEXT]: [],
  [Feature.VEGETABLE_WEIGHT_IN_GRAMS]: [69741, 2, 72178],
};

const useFeatureFlag = (feature: Feature) => {
  const { user } = useAuth();

  if (typeof window !== `undefined` && localStorage.getItem(feature)) {
    return true;
  }

  if (!user || !Object.prototype.hasOwnProperty.call(FEATURE_ACCESS, feature)) {
    return false;
  }

  return FEATURE_ACCESS[feature].includes(user.id!);
};

export default useFeatureFlag;
