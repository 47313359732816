import { useLocale } from "next-intl";

import { Brand } from "@efarmz/efarmz-domain-typescript";

import Gallery from "@/components/atoms/Gallery";

import BrandCard from "@/components/molecules/BrandCard";

import { Link } from "@/utils/navigation";

type BrandListSliderProps = {
  brands: Brand[];
  className?: string;
};

const BrandListSlider = ({ brands, className }: BrandListSliderProps) => {
  const locale = useLocale();

  const Items = brands?.length
    ? brands.map((brand: any) => {
        return (
          <Link
            href={{
              pathname: `/brands/[slug]`,
              params: { slug: brand.id },
            }}
            key={brand.id}
            className="!h-auto min-w-72 snap-end"
          >
            <BrandCard brand={brand} className="h-full" />
          </Link>
        );
      })
    : [];

  return <Gallery className="min-h-[450px]">{Items}</Gallery>;
};

export default BrandListSlider;
