import Image from "next/image";
import { twMerge } from "tailwind-merge";

import SparkleImg from "@/images/sketch-sparkle.svg";
import RedLine from "@/svg/RedLine";

/*
const RandomLines = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={7}
    className="-bottom-5 bg-transparent overflow-visible absolute w-full max-w-sm left-0"
    preserveAspectRatio="none"
    viewBox="0 0 100 7"
    {...props}
  >
    <path
      d="M11-1q24 0 77 3Q37 1 7 3q46 2 95 3"
      className="stroke-rouge-500 fill-rouge-500"
    />
  </svg>,
];
*/

const random = (from: number, to: number) =>
  Math.floor(Math.random() * (to - from) + from);

const Underline = ({ children }: any) => {
  const strokeWidth = random(16, 20) / 100;
  const height = random(4, 8);
  let lines = random(2, 4);
  let d = `M ${random(-5, 15)} ${random(-2, 2)}`;
  let line = 0;

  // Draw the lines
  while (line++ < lines) {
    const y = line * (height / lines); // Draw every line lower than the previous one
    d +=
      ` Q ${random(30, 70)}` + // The x coordinate of the curve's center
      ` ${random(y - 5, y + 5)}` + // The y coordinate of the curve's center
      ` ${line % 2 === 0 ? random(-5, 15) : random(85, 105)}` + // The x coordinate of the curve's end, alternating between right to left based on the current line number
      ` ${random(y - 2, y + 2)}`; // The y coordinate of the curve's end
  }

  return (
    <div className="relative inline">
      {children}
      <svg
        viewBox={`0 0 100 ${height}`}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        className="-bottom-5 bg-transparent overflow-visible absolute w-full max-w-sm left-0"
      >
        <path
          d={d}
          strokeWidth={`${strokeWidth}em`}
          className="stroke-rouge-500 fill-rouge-500"
        />
      </svg>
    </div>
  );
};

type SectionHeaderProps = {
  title: React.ReactNode;
  titleProps?: any;
  variant?: `line` | `sparkle` | `default`;
  subtitle?: string;
  className?: string;
  left?: React.ReactNode;
  gutter?: boolean;
};

const SectionHeader = ({
  title,
  subtitle,
  variant = `default`,
  className,
  titleProps,
  left,
  gutter = true,
}: SectionHeaderProps) => {
  const { className: titleClassname } = titleProps || {};
  return (
    <div className={twMerge(`flex justify-between text-vert-500 `, className)}>
      <div className={twMerge(`grow`, gutter && `lg:mx-0`)}>
        <div className="relative inline-block">
          <h2
            className={twMerge(
              `mt-2 text-3xl font-black tracking-tight md:text-4xl font-secondary bg-gradient-to-r from-vert-500  to-vert-600 inline-block text-transparent bg-clip-text`,
              titleClassname
            )}
          >
            {title}
          </h2>

          {variant === `sparkle` && (
            <Image
              src={SparkleImg}
              alt=""
              className="max-w-[28px] absolute -top-3 -right-8"
            />
          )}
          {variant === `line` && (
            <RedLine className="absolute -bottom-2 left-0 max-w-[120px]" />
          )}
          {left && left}
        </div>
        {subtitle && (
          <p className="mt-3 md:mt-6 md:text-lg leading-8 text-vert-500">
            {subtitle}
          </p>
        )}
      </div>
    </div>
  );
};

export default SectionHeader;
