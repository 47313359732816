import {
  addWeeks,
  endOfWeek,
  format,
  getDay,
  getHours,
  getWeek,
  getYear,
  startOfWeek,
} from "date-fns";
import { useTranslations } from "next-intl";

import { Cart, Meal, Product } from "@efarmz/efarmz-domain-typescript";

import useDeliveryWindow from "./useDeliveryWindow";

const useProductCalendar = (): {
  getBoxNextPeriods: () => string[];
  getBoxCurrentPeriod: () => string;
  getBoxPeriodLabel: (period: string) => string;
  checkCartDateAvailability: (cart: Cart, date: Date) => void;
} => {
  const t = useTranslations();

  const { longDeliveryDate } = useDeliveryWindow();

  const currentYear = getYear(new Date());

  const currentDay = getDay(longDeliveryDate || new Date());
  const currentHour = getHours(longDeliveryDate || new Date());

  const calculatedDate = currentDay >= 3 ? addWeeks(new Date(), 1) : new Date();
  const currentWeek = getWeek(calculatedDate);

  const getBoxCurrentPeriod = () => {
    return `${getYear(calculatedDate)}${currentWeek.toLocaleString(`en-US`, {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`;
  };

  const getBoxNextPeriods = () => {
    return [addWeeks(calculatedDate, 1), addWeeks(calculatedDate, 2)].map(
      (date) =>
        `${getYear(date)}${getWeek(date).toLocaleString(`en-US`, {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}`
    );
  };

  const getBoxPeriodLabel = (period: string) => {
    const year = parseInt(period.slice(0, 4));
    const week = parseInt(period.slice(-2));

    const weekAsDate = getDateOfISOWeek(year, week);

    return t(`dataset.BoxPeriod.period`, {
      from: format(
        startOfWeek(weekAsDate, {
          weekStartsOn: 1,
        }),
        `dd/MM`
      ),
      to: format(
        endOfWeek(weekAsDate, {
          weekStartsOn: 1,
        }),
        `dd/MM`
      ),
    });
  };

  const checkCartDateAvailability = (cart: Cart, deliveryDate: Date) => {
    const deliveryWeek = `${getYear(deliveryDate)}${getWeek(deliveryDate)}`;

    const unavailableMeals: Meal[] = [];
    const unavailableProducts: Product[] = [];

    cart?.meals?.items?.forEach((orderItem) => {
      if (!orderItem?.item?.parent?.availableWeeks?.includes(deliveryWeek)) {
        unavailableMeals.push(orderItem.item);
      }
    });
  };

  return {
    getBoxCurrentPeriod,
    getBoxNextPeriods,
    getBoxPeriodLabel,
    checkCartDateAvailability,
  };
};

export default useProductCalendar;

function getDateOfISOWeek(year: number, week: number) {
  // Get date for 1 Jan in given year
  let d = new Date(year, 0, 1);
  let dow = d.getDay();

  // Shift to start of ISO week 1
  d.setDate((dow <= 4 ? 2 : 9) - d.getDay());

  // Add required number of weeks
  d.setDate(d.getDate() + (week - 1) * 7);

  return d;
}

const getOptionFromYearAndWeek = (year: number, week: number) => {
  const weekAsDate = getDateOfISOWeek(year, week);
  return {
    value: `${year}${week}`,
    label: `Semaine ${week} : ${format(
      startOfWeek(weekAsDate, {
        weekStartsOn: 1,
      }),
      `dd/MM`
    )} - ${format(
      endOfWeek(weekAsDate, {
        weekStartsOn: 1,
      }),
      `dd/MM`
    )}`,
  };
};
