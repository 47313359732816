import { PortableText, PortableTextReactComponents } from "@portabletext/react";
import { twMerge } from "tailwind-merge";

import SanityImage from "@/components/atoms/SanityImage";

import SectionHeader from "@/components/layouts/SectionHeader";

type Section = {
  title?: string;
  subtitle?: string;
  content?: any;
  image?: any;
  align?: `LEFT` | `CENTER` | `RIGHT`;
  variant?: `TEXT-ONLY` | `IMAGE-LEFT` | `IMAGE-RIGHT`;
  width?: `AUTO` | `FULL`;
};

type SanityRichTextSectionProps = {
  section: Section;
  className?: string;
  legacy?: boolean;
};

const SanityRichTextSection = ({
  section,
  className,
  legacy = false,
}: SanityRichTextSectionProps) => {
  const { content, align = `LEFT`, variant, width = `AUTO` } = section;

  const components: Partial<PortableTextReactComponents> = {
    types: {
      image: ({ value }) => {
        return (
          <SanityImage
            image={value!.asset!}
            alt=""
            effect="fade"
            className="rounded-lg"
          />
        );
      },
    },
    marks: {
      link: ({ value, children }) => {
        return (
          <a
            href={value.href}
            target={
              legacy && value.href.includes(`efarmz.be`) ? `_parent` : undefined
            }
          >
            {children}
          </a>
        );
      },
    },
  };

  if (variant === `IMAGE-LEFT` || variant === `IMAGE-RIGHT`) {
    return (
      <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
        <div
          className={twMerge(
            `px-6 lg:px-0 lg:pt-4`,
            variant === `IMAGE-LEFT` && `order-last`,
            variant === `IMAGE-LEFT` && `flex justify-end`,
            variant === `IMAGE-RIGHT` && `flg:pr-4`
          )}
        >
          <div>
            <SectionHeader
              title={<span>{section?.title}</span>}
              subtitle={section.subtitle}
              className="mb-10 sm:mb-10"
              variant={legacy ? `line` : `default`}
            />
            <div
              className={twMerge(
                `prose prose-zinc prose-headings:text-vert-500`
              )}
            >
              <PortableText value={content} components={components} />
            </div>
          </div>
        </div>
        <div className="sm:px-6 lg:px-0">
          <div className="relative isolate overflow-hidden bg-menthe-300 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pr-0 lg:mx-0 lg:max-w-none">
            <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
              {section?.image && (
                <div
                  className="relative"
                  style={{
                    aspectRatio: `auto 2432 / 1442`,
                  }}
                >
                  <SanityImage
                    image={section.image}
                    alt="Product screenshot"
                    fill
                    sizes="610px"
                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl object-cover"
                  />
                </div>
              )}
            </div>
            <div
              className="pointerevents-none absolute inset-0  sm:rounded-3xl"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={twMerge(
        `flex`,
        variant === `TEXT-ONLY` && align === `LEFT` && `justify-start`,
        variant === `TEXT-ONLY` && align === `RIGHT` && `justify-end`,
        variant === `TEXT-ONLY` && align === `CENTER` && `justify-center`,
        className
      )}
    >
      <div
        className={twMerge(
          `prose prose-zinc prose-headings:text-vert-500`,
          width === `FULL` && `max-w-none`
        )}
      >
        <PortableText value={content} components={components} />
      </div>
    </div>
  );
};

export default SanityRichTextSection;
