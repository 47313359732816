import { Configure, useHits } from "react-instantsearch";

import { useLocale } from "next-intl";
import { SwiperSlide } from "swiper/react";

import Slider from "@/components/atoms/Slider";

import BrandCard from "@/components/molecules/BrandCard";

import SearchProvider from "@/components/search/SearchProvider";
import { Link } from "@/utils/navigation";

const Hits = (props: any) => {
  const locale = useLocale();
  const { hitProps, ...other } = props;
  const { hits }: any = useHits(other);

  const { className: hitClassName, style: hitStyle } = hitProps;

  const Items = hits.map((hit: any) => {
    return (
      <SwiperSlide key={hit.objectID} className="!h-auto">
        <Link
          href={{
            pathname: `/brands/[slug]`,
            params: { slug: hit.id },
          }}
        >
          <BrandCard brand={hit} className="h-full" />
        </Link>
      </SwiperSlide>
    );
  });

  return (
    <Slider
      slidesPerView={2}
      spaceBetween={10}
      pagination={{
        clickable: true,
      }}
      className="mySwiper"
      breakpoints={{
        640: {
          slidesPerView: 1.5,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3.5,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4.5,
          spaceBetween: 20,
        },
      }}
    >
      {Items}
    </Slider>
  );
};

type BrandListSliderElasticProps = {
  settings: any;
  filters?: any;
  className?: string;
  hitProps?: {
    className?: string;
    style?: object;
  };
};

const BrandListSliderElastic = ({
  className,
  settings,
  filters,
  hitProps = {},
}: BrandListSliderElasticProps) => {
  const locale = useLocale();

  return (
    <div className={className}>
      <SearchProvider index={`brands-${locale}`} settings={settings}>
        <Configure filters={filters} />

        <Hits hitProps={hitProps} />
      </SearchProvider>
    </div>
  );
};

export default BrandListSliderElastic;
