const RedLine = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 218 7"
    fill="none"
    {...props}
  >
    <path
      fill="#F04E37"
      d="M177.277 1.742c.601-.06 3.325.253 3.769.09 1.489.037 4.057.18 4.438.108 1.437.146 3.787-.228 4.34.068-.081.041-.111.047.167.109l.001-.037.458.079a3.16 3.16 0 0 1 1.478.103 20.45 20.45 0 0 0 2.824-.122l.557.124c.17.004.1.002.112-.047.567.094 8.669.111 8.787.213.36.076.746.086 1.11.027l2.898.119a15.338 15.338 0 0 1 1.999.048c1.998.104 3.667.57 4.328.548.493.006.977.092 1.404.25 1.062-.036 2.167 1 1.755 1.059l-.636.249c-.401.04-2.008.302-1.867.275-1.649.348-4.739-.114-4.921-.033-.469-.048-.531.05-5.077.253-.33-.008-5.213.053-5.473.053.005-.197-1.201.038-1.286-.173-.42-.01-.851.016-.893.095-1.162.09-1.853-.29-3.068-.117l-.175-.195c-.87-.021-.334.164-1.533.086-.328-.063-1.186.236-1.866.22-4.578-.11-8.753-.384-11.126-.27-.421.028 1.519.038 1.514.235-6.211-.021-13.478.197-20.199.127-2.41-.003-4.161-.396-6.632-.413l.341-.035c-.618-.058-5.294.525-5.859.32-2.149-.051-1.586-.198-3.599-.086a4.092 4.092 0 0 0-1.686-.17l-2.735.167c-2.001.02-4.271-.029-6.504.059 1.466.214-.071.472-2.997-.128-1.09-.027-1.206.223-2.123.096-.885.164-2.088.295-3.152.435-5.003.076-11.291-.5-17.21-.613l-.901.034c-.996-.166-16.745.827-17.308.511-.2.032-.63.04-.602.103-2.782.05-4.997-.183-7.83-.134l.26.006c-5.344.142-11.435.087-16.19.225a18.899 18.899 0 0 0-3.235-.226c-5.146.202-11.652.37-17.687.477-5.613.074-11.501-.068-16.878.14-8.12-.079-16.166.047-24.21-.27l.068.08C2.267 5.814.69 5.659.55 5.273c-.141-.385 1.576-.633 1.576-.633 2.349-.35 5.423-.485 8.09-.722 1.353-.134 6.772-.452 6.422-.46-3.79-.006 7.867-.58 11.021-.732l-.228-.085c8.753-.405 16.363-.32 24.887-.378 2.337-.282 4.914-.128 7.361-.389-1.574.135 1.458.097 1.243.32l3.314-.136c.356.08.732.119 1.108.113 3.49.011 19.44-.607 18.922-.712 2.914-.133 5.89-.338 9.197-.554-1.35-.033-1.119-.082-.29-.062 5.528.201 20.109.32 25.497.45 9.718.235 19.123.524 27.542-.147.842-.066 5.206.243 6.198.15-1.002.08-5.356-.228-6.198-.15 1.343-.11 6.092.005 7.392-.006 22.25-.121 23.67.695 23.673.603Z"
    />
  </svg>
);

export default RedLine;
