"use client";

import { useSearchParams } from "next/navigation";
import { twMerge } from "tailwind-merge";

import Container from "@efarmz/efarmz-react-commons/esm/components/atoms/Container";

import useAuth from "@/hooks/useAuth";

import SanityCtaSection from "../SanityCtaSection";
import SanityMealBoxSection from "../SanityMealBoxSection";
import SanityTestimonialGridSection from "../SanityTestimonialGridSection";

import SectionHeader from "@/components/layouts/SectionHeader";
import SanityBrandSliderSection from "@/components/sanity/SanityBrandSliderSection";
import SanityHeroSection from "@/components/sanity/SanityHeroSection";
import SanityProductCategorySliderSection from "@/components/sanity/SanityProductCategorySliderSection";
import SanityProductTagSection from "@/components/sanity/SanityProductTagSection";
import SanityRichTextSection from "@/components/sanity/SanityRichTextSection";

type SanityLayoutBuilderProps = {
  sections: any[];
  legacy?: boolean;
};
const SanityLayoutBuilder = ({
  sections,
  legacy = true,
}: SanityLayoutBuilderProps) => {
  const searchParams = useSearchParams();
  const { user } = useAuth();

  const authUser =
    searchParams?.has(`authUser`) || user
      ? { id: 1, email: `benjamin@efarmz.be` }
      : null;

  const Sections = sections?.length
    ? sections
        .filter((section: any) => {
          return (
            (authUser && section?.visibility?.includes(`RECOGNIZED`)) ||
            (!authUser && section?.visibility?.includes(`UNRECOGNIZED`))
          );
        })
        .map((section: any, i: number) => {
          if (section._type === `HeroSection`) {
            return (
              <SanityHeroSection
                section={section}
                legacy={legacy}
                key={section._key}
              />
            );
          }

          return (
            <Container
              className={twMerge(
                `py-5 sm:py-10`,
                legacy && `py-10 sm:py-20`,
                i % 2 !== 0 && `bg-menthe-100/70`,
                section._type === `RichTextSection` && `sm:py-20`
              )}
              key={section._key}
            >
              {section.title &&
                !legacy &&
                section._type !== `ProductTagSection` &&
                section._type !== `CtaSection` &&
                section._type !== `RichTextSection` && (
                  <SectionHeader
                    title={<span>{section.title}</span>}
                    subtitle={section.subtitle}
                    className="mb-10 sm:mb-10"
                    variant={legacy ? `line` : `default`}
                  />
                )}
              {section._type === `RichTextSection` && (
                <SanityRichTextSection section={section} legacy={legacy} />
              )}
              {section._type === `CtaSection` && (
                <SanityCtaSection section={section} legacy={legacy} />
              )}
              {section._type === `ProductTagSection` && (
                <SanityProductTagSection section={section} legacy={legacy} />
              )}
              {section._type === `BrandSection` && (
                <SanityBrandSliderSection section={section} legacy={legacy} />
              )}
              {section._type === `TestimonialSection` && (
                <SanityTestimonialGridSection section={section} />
              )}
              {section._type === `ProductSection` && (
                <SanityProductCategorySliderSection
                  section={section}
                  legacy={legacy}
                />
              )}
              {section._type === `MealBoxSection` && (
                <SanityMealBoxSection section={section} legacy={legacy} />
              )}
            </Container>
          );
        })
    : [];
  return <>{Sections}</>;
};

export default SanityLayoutBuilder;
