import { Configure, useHits } from "react-instantsearch";

import { useLocale } from "next-intl";

import Gallery from "@/components/atoms/Gallery";

import MealCard from "@/components/molecules/MealCard";

import SearchProvider from "@/components/search/SearchProvider";
import { Link } from "@/utils/navigation";

const BoxHits = (props: any) => {
  const { legacy, Action, ...other } = props;
  const locale = useLocale();
  const { hits }: any = useHits(other);

  const Items = hits.map((hit: any) => {
    return (
      <Link
        href={`/box/?mealId=${hit.id}` as any}
        key={hit.id}
        className="!h-auto min-w-72 snap-end"
      >
        <MealCard
          meal={hit}
          className="h-full"
          tagProps={{
            size: `md`,
            className: `rounded-lg`,
          }}
          titleProps={{
            className: `text-vert-600 font-semibold text-lg md:text-md md:font-bold`,
          }}
        />
      </Link>
    );
  });

  return (
    <Gallery className="min-h-[390px]" Action={Action}>
      {Items}
    </Gallery>
  );
};

type BoxListSliderElasticProps = {
  settings: any;
  filters: any;
  legacy?: boolean;
  Action?: React.ReactNode;
};
const BoxListSliderElastic = ({
  settings,
  filters,
  legacy = false,
  Action,
}: BoxListSliderElasticProps) => {
  const locale = useLocale();

  return (
    <SearchProvider index={`orderable-items-${locale}`} settings={settings}>
      <Configure filters={filters} />

      <div className="min-h-[273px]">
        <BoxHits legacy={legacy} Action={Action} />
      </div>
    </SearchProvider>
  );
};
export default BoxListSliderElastic;
