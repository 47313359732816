const testimonials = [
  {
    body: `Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.`,
    author: {
      name: `Leslie Alexander`,
      handle: `lesliealexander`,
      imageUrl: `https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`,
    },
  },

  {
    body: `Aut reprehenderit voluptatem eum asperiores beatae id. Iure molestiae ipsam ut officia rem nulla blanditiis.`,
    author: {
      name: `Lindsay Walton`,
      handle: `lindsaywalton`,
      imageUrl: `https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`,
    },
  },
  {
    body: `Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.`,
    author: {
      name: `Leslie Alexander`,
      handle: `lesliealexander`,
      imageUrl: `https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`,
    },
  },

  {
    body: `Aut reprehenderit voluptatem eum asperiores beatae id. Iure molestiae ipsam ut officia rem nulla blanditiis.`,
    author: {
      name: `Lindsay Walton`,
      handle: `lindsaywalton`,
      imageUrl: `https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`,
    },
  },
  {
    body: `Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.`,
    author: {
      name: `Leslie Alexander`,
      handle: `lesliealexander`,
      imageUrl: `https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`,
    },
  },

  {
    body: `Aut reprehenderit voluptatem eum asperiores beatae id. Iure molestiae ipsam ut officia rem nulla blanditiis.`,
    author: {
      name: `Lindsay Walton`,
      handle: `lindsaywalton`,
      imageUrl: `https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`,
    },
  },

  {
    body: `Voluptas quos itaque ipsam in voluptatem est. Iste eos blanditiis repudiandae. Earum deserunt enim molestiae ipsum perferendis recusandae saepe corrupti.`,
    author: {
      name: `Tom Cook`,
      handle: `tomcook`,
      imageUrl: `https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`,
    },
  },
  // More testimonials...

  {
    body: `Molestias ea earum quos nostrum doloremque sed. Quaerat quasi aut velit incidunt excepturi rerum voluptatem minus harum.`,
    author: {
      name: `Leonard Krasner`,
      handle: `leonardkrasner`,
      imageUrl: `https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`,
    },
  },
];

type SanityTestimonialGridSectionProps = {
  section: any;
};

const SanityTestimonialGridSection = ({
  section,
}: SanityTestimonialGridSectionProps) => {
  const Testimonials = section?.testimonials?.length
    ? section.testimonials.map((testimonial: any) => {
        return (
          <div
            key={testimonial._key}
            className="pt-8 sm:inline-block sm:w-full sm:px-4"
          >
            <figure className="rounded-2xl bg-white p-8 text-sm leading-6">
              <blockquote className="text-gray-900">
                <p>{`“${testimonial.content}”`}</p>
              </blockquote>
              <figcaption className="mt-6 flex items-center gap-x-4">
                <div>
                  <div className="font-semibold text-gray-900">
                    {testimonial.username}
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        );
      })
    : [];
  return (
    <div>
      <div>
        <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {Testimonials}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SanityTestimonialGridSection;
