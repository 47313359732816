import React from "react";

import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import Feature from "@/types/Feature";

import { Meal, OrderableItemTag } from "@efarmz/efarmz-domain-typescript";
import { ButtonProps } from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";
import Tag from "@efarmz/efarmz-react-commons/esm/components/atoms/Tag";
import { TagProps } from "@efarmz/efarmz-react-commons/esm/components/atoms/Tag/Tag";
import Tooltip from "@efarmz/efarmz-react-commons/esm/components/atoms/Tooltip";
import useCurrency from "@efarmz/efarmz-react-commons/esm/hooks/useCurrency";

import Image, { ImageProps } from "@/components/atoms/Image";

import useFeatureFlag from "@/hooks/useFeatureFlag";

import Carrot from "@/svg/Carrot";

export const search_attributes = [
  `id`,
  `name`,
  `type`,
  `brand`,
  `image`,
  `finalPrice`,
  `cataloguePrice`,
  `vegetableWeightInGrams`,
  `averageRate`,
  `availableWeeks`,
  `location`,
  `tags`,
  `variants`,
  `moreHtml`,
  `advice`,
];

const prices: any = {
  [OrderableItemTag.PREMIUM_1_5]: 1.5,
  [OrderableItemTag.PREMIUM_75]: 0.75,
};

type MealCardProps = {
  meal: Meal;
  tooltip?: string;
  selected?: boolean;
  className?: string;
  footerProps?: React.HTMLProps<HTMLDivElement>;
  buttonProps?: Omit<ButtonProps, `ref`>;
  titleProps?: React.HTMLProps<HTMLDivElement>;
  tagFilter?: (tag: OrderableItemTag) => boolean;
  tagProps?: Omit<TagProps, `tag`>;
  imageProps?: Omit<ImageProps, `src` | `alt`>;
  children?: React.ReactNode;
  showTags?: boolean;
  showBio?: boolean;
};

const MealCard = ({
  meal,
  tooltip,
  selected = false,
  className,
  imageProps = {},
  footerProps = {},
  buttonProps = {},
  tagFilter = (tag) =>
    ![
      OrderableItemTag.PREMIUM_1_5,
      OrderableItemTag.PREMIUM_75,
      OrderableItemTag.BIO,
      OrderableItemTag.WARM,
      OrderableItemTag.WARM_2_45,
      OrderableItemTag.WARM_2_95,
    ].includes(tag),
  tagProps = {},
  titleProps = {},
  showTags = true,
  showBio = true,
  children,
}: MealCardProps) => {
  const currency = useCurrency();
  const t = useTranslations();
  const { className: footerClassName, ...otherFooterProps } = footerProps;
  const { className: buttonClassName, ...otherButtonProps } = buttonProps;
  const { className: titleClassName, ...otherTitleProps } = titleProps;
  const { className: tagClassName, ...otherTagProps } = tagProps;

  const hasVegetableWeightInGramsAccess = useFeatureFlag(
    Feature.VEGETABLE_WEIGHT_IN_GRAMS
  );

  const Tags = meal?.tags?.length
    ? meal.tags.filter(tagFilter).map((tag) => {
        return (
          <Tag
            key={tag}
            label={t(`dataset.Tags.${tag}`)}
            size="xs"
            showIcon={false}
            className={twMerge(`rounded-sm`, tagClassName)}
            {...(otherTagProps || {})}
            tag={tag}
          />
        );
      })
    : [];

  return (
    <div
      className={twMerge(
        `relative group flex flex-col w-full overflow-hidden rounded-lg bg-white focus-within:ring-2 focus-within:ring-choux focus-within:ring-offset-2 focus-within:ring-offset-gray-100 ring-choux-500`,
        className
      )}
    >
      <div
        className={twMerge(
          `relative aspect-w-6 aspect-h-5 bg-menthe-300`,
          selected && `opacity-40`
        )}
      >
        <Image
          fill
          sizes="(max-width: 710px) 500px, 290px"
          className="pointer-events-none object-cover group-hover:opacity-75 absolute top-0 left-0 right-0"
          {...imageProps}
          alt={meal.name!}
          src={`https://cdn.efarmz.be/art/${meal.image}`}
        />

        <div className="absolute bottom-0 left-0 top-0 right-0 flex flex-col justify-end items-start py-2 px-4">
          <div className="flex w-full items-end">
            <div className="flex-1 flex items-start justify-start gap-2">
              {showBio && meal?.tags?.includes(OrderableItemTag.BIO) ? (
                <Tag
                  tag={OrderableItemTag.BIO}
                  label={t(
                    `dataset.Tags.${OrderableItemTag.BIO}`
                  )?.toLocaleUpperCase()}
                  size="xs"
                  active
                  showIcon={false}
                  className="font-semibold rounded-lg"
                />
              ) : null}
              {(meal?.tags?.includes(OrderableItemTag.PREMIUM_1_5) ||
                meal?.tags?.includes(OrderableItemTag.PREMIUM_75)) && (
                <div className="bg-terreau-500 text-xs text-white font-semibold rounded-lg h-[22.5px] py-1 px-2">
                  Premium +
                  {currency.format(
                    prices[
                      meal?.tags?.find((tag) =>
                        tag.startsWith(`PREMIUM`)
                      ) as any
                    ]
                  )}
                </div>
              )}
              {meal?.tags?.includes(OrderableItemTag.WARM) && (
                <Tag
                  tag={OrderableItemTag.WARM}
                  label={`${t(
                    `dataset.Tags.${OrderableItemTag.WARM}`
                  )} +${currency.format(
                    meal?.tags?.includes(OrderableItemTag.WARM_2_95)
                      ? 2.95
                      : 2.45
                  )}`}
                  size="xs"
                  active
                  showIcon={false}
                  className="font-semibold rounded-lg"
                />
              )}
              {hasVegetableWeightInGramsAccess &&
              meal?.vegetableWeightInGrams ? (
                <Tooltip content="Quantité de légumes par portion">
                  <span className="bg-vert-700 inline-flex items-center gap-1 py-1 px-2 text-white text-xs font-semibold rounded-md">
                    <Carrot className="fill-white h-3 w-3" />
                    {` `}
                    {meal?.vegetableWeightInGrams}g
                  </span>
                </Tooltip>
              ) : null}
            </div>

            <div>
              {meal?.tags?.includes(OrderableItemTag.CHLOE_TIPS) && (
                <Image
                  width={40}
                  height={40}
                  sizes="290px"
                  className="pointer-events-none object-cover  w-16 h-16 rounded-full border-4 border-menthe-100"
                  alt={meal.name!}
                  src={`https://cdn.efarmz.be/people/chloe.jpg`}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={twMerge(
          `py-4 px-4 flex-1 grow h-auto flex flex-col text-center gap-3`,
          footerClassName
        )}
        {...otherFooterProps}
      >
        <div className="flex-1">
          <h4
            className={twMerge(
              `text-md font-bold md:text-lg md:font-semibold text-terreau text-left w-full`,
              selected && `opacity-40`,
              titleClassName
            )}
            {...otherTitleProps}
          >
            {meal.name}
          </h4>
        </div>
        {showTags && Tags?.length > 0 && (
          <div className={twMerge(selected && `opacity-40`)}>
            <div className="flex items-end justify-start  gap-2 mt-4 flex-wrap opacity-100">
              {Tags}
            </div>
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export default MealCard;
