import SanityImage from "@/components/atoms/SanityImage";

import SanityButton from "../SanityButton";

type Section = {
  title?: string;
  subtitle?: string;
  bgColor?: any;
  image?: any;
  buttons: any[];
};

type SanityCtaSectionProps = {
  section: Section;
  legacy?: boolean;
};

function pickTextColorBasedOnBgColorSimple(
  bgColor: string,
  lightColor: string,
  darkColor: string
) {
  var color = bgColor.charAt(0) === `#` ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
}

const SanityCtaSection = ({
  section,
  legacy = false,
}: SanityCtaSectionProps) => {
  const Buttons = section?.buttons?.length
    ? section?.buttons.map((button, i) =>
        button?.url ? (
          <a
            key={i.toString()}
            href={button.url}
            target={!button.url?.includes(`.efarmz.`) ? `_blank` : undefined}
          >
            <SanityButton {...button} size="xl" />
          </a>
        ) : (
          <SanityButton {...button} key={i.toString()} size="xl" />
        )
      )
    : [];

  return (
    <div
      className="overflow-hidden rounded-lg lg:grid lg:grid-cols-2 lg:gap-4 bg-menthe-200"
      style={{ backgroundColor: section?.bgColor?.hex }}
    >
      <div className="px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:px-20 xl:py-20">
        <div className="lg:self-center">
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl text-vert-500">
            {section?.title}
          </h2>
          <p className="mt-4 text-lg leading-6 opacity-70 text-menthe-700 font-medium">
            {section?.subtitle}
          </p>
          <div className="mt-8 space-x-2">{Buttons}</div>
        </div>
      </div>
      {section?.image && (
        <div className="aspect-h-3 aspect-w-5 -mt-6 md:aspect-h-1 md:aspect-w-2">
          <SanityImage
            className="translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
            image={section?.image}
            alt="CTA"
          />
        </div>
      )}
    </div>
  );
};

export default SanityCtaSection;
