"use client";

import { useEffect } from "react";

import useSegment from "@/hooks/useSegment";

type PageTrackerProps = {
  name: string;
};

const PageTracker = ({ name }: PageTrackerProps) => {
  const segment = useSegment();
  useEffect(() => {
    segment?.page({ name });
  }, []);

  return null;
};

export default PageTracker;
