import { twMerge } from "tailwind-merge";

import { Brand } from "@efarmz/efarmz-domain-typescript";

import Image, { ImageProps } from "@/components/atoms/Image";

type BrandCardProps = {
  brand: Brand;
  className?: string;
  imageProps?: Omit<ImageProps, `src` | `alt`>;
};

const BrandCard = ({ brand, className, imageProps }: BrandCardProps) => {
  return (
    <div
      className={twMerge(
        `relative group flex flex-col aspect-w-3 aspect-h-4 w-full overflow-hidden rounded-lg bg-white focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100`,
        className
      )}
    >
      {brand?.image && (
        <Image
          src={`https://cdn.efarmz.be/Ana/${brand.image}`}
          fill
          alt={brand.name || ``}
          sizes="260px"
          className="pointer-events-none object-cover group-hover:opacity-75 absolute top-0 bottom-0 left-0 right-0"
          {...imageProps}
        />
      )}

      <div
        aria-hidden="true"
        className="bg-gradient-to-b from-transparent via-transparent to-black opacity-100 absolute top-0 bottom-0 left-0 right-0"
      />

      <div className="absolute h-full flex flex-col justify-end py-8 px-4 items-center">
        <div className="relative">
          <h4 className="text-3xl font-semibold text-menthe-200 font-secondary">
            {brand?.name}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default BrandCard;
