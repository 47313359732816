"use client";
import React, { Children, forwardRef, useState } from "react";

import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/outline";
import {
  Swiper as SwiperSlider,
  SwiperProps,
  SwiperSlide,
  SwiperSlideProps,
} from "swiper/react";
import { Swiper } from "swiper/types";
import { twMerge } from "tailwind-merge";

export const Slide = forwardRef<any, SwiperSlideProps>(
  (props: SwiperSlideProps, ref) => {
    const { className, ...other } = props;
    return <SwiperSlide className={twMerge(`!h-auto`, className)} {...other} />;
  }
);

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

Slide.displayName = `Slide`;

type SwiperState = {
  activeIndex: number;
  allowSlidePrev: boolean;
  allowSlideNext: boolean;
};

const Slider = (props: SwiperProps) => {
  const [isInit, setIsInit] = useState<boolean>(false);
  const [swiper, setSwiper] = useState<Swiper | null>(null);
  const [swiperState, setSwiperState] = useState<SwiperState>({
    activeIndex: 0,
    allowSlidePrev: false,
    allowSlideNext: true,
  });

  const { className, children, ...other } = props;

  const handleClickPrevious = () => {
    if (swiper && swiperState.allowSlidePrev) {
      swiper.slidePrev();
    }
  };
  const handleClickNext = () => {
    if (swiper && swiperState.allowSlideNext) {
      swiper.slideNext();
    }
  };

  const handleSlideChange = (_swiper: Swiper) => {
    setSwiperState({
      activeIndex: _swiper.snapIndex,
      allowSlidePrev: _swiper.snapIndex >= 0,
      allowSlideNext: _swiper.snapIndex < _swiper.snapGrid?.length - 1,
    });
  };

  return (
    <div className={className}>
      <SwiperSlider
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
        {...other}
        onSwiper={setSwiper as any}
        onAfterInit={() => setIsInit(true)}
        onSlideChange={handleSlideChange}
        mousewheel={{
          forceToAxis: true,
        }}
        className="pb-2"
        cssMode
      >
        {children}
      </SwiperSlider>
      {isInit && Children.count(children) ? (
        <div className="mt-6 flex justify-end">
          <button
            className={twMerge(
              `text-menthe-500`,
              swiperState.allowSlidePrev &&
                swiperState.activeIndex > 0 &&
                `text-vert-500`
            )}
            disabled={
              !swiperState.allowSlidePrev || swiperState.activeIndex === 0
            }
            onClick={handleClickPrevious}
          >
            <ArrowLongLeftIcon className="w-8" />
          </button>
          <button
            className={twMerge(
              `text-menthe-500`,
              swiperState.allowSlideNext && `text-vert-500`
            )}
            disabled={!swiperState.allowSlideNext}
            onClick={handleClickNext}
          >
            <ArrowLongRightIcon className="w-8" />
          </button>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default Slider;
