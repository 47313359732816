import React from "react";

import { twMerge } from "tailwind-merge";

type MealCardSkeletonProps = {
  className?: string;

  children?: React.ReactNode;
  showTags?: boolean;
};

const MealCardSkeleton = ({
  className,

  showTags = true,

  children,
}: MealCardSkeletonProps) => {
  const Tags = Array.from(Array(3).keys()).map((key) => {
    return <div key={key} className="bg-vert-500/5 h-8 w-28 rounded-lg" />;
  });

  return (
    <div
      className={twMerge(
        `relative group flex flex-col w-full overflow-hidden rounded-lg bg-white focus-within:ring-2 focus-within:ring-choux focus-within:ring-offset-2 focus-within:ring-offset-gray-100 ring-choux-500`,
        className
      )}
    >
      <div className="relative aspect-w-6 aspect-h-5 bg-menthe-300 animate-pulse" />

      <div className="py-4 px-4 flex-1 grow h-auto flex flex-col text-center gap-3">
        <div className="flex-1">
          <h4 className="text-md font-bold md:text-lg md:font-semibold text-terreau text-left w-full">
            <span className="block w-full h-3 bg-vert-500/50 rounded-full animate-pulse" />
            <span className="block w-1/3 h-3 bg-vert-500/50 rounded-full animate-pulse mt-1" />
          </h4>
        </div>
        {showTags && Tags?.length > 0 && (
          <div className="flex items-end justify-start  gap-2 mt-4 flex-wrap opacity-100">
            {Tags}
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export default MealCardSkeleton;
