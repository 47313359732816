import Button, {
  ButtonProps,
} from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

type Section = {
  content: any;
};

type SanityButtonProps = ButtonProps & {
  text?: string;
  url?: string;
  variant?: string;
  color?: any;
  legacy?: boolean;
};

function pickTextColorBasedOnBgColorSimple(
  bgColor: string,
  lightColor: string,
  darkColor: string
) {
  var color = bgColor.charAt(0) === `#` ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
}

const SanityButton = (props: SanityButtonProps) => {
  const { text, url, variant, color, legacy, ...other } = props as any;
  return (
    <Button
      variant={variant}
      style={{
        background: variant === `contained` ? color?.hex : `${color?.hex}10`,
        color:
          variant === `contained`
            ? pickTextColorBasedOnBgColorSimple(
                color?.hex,
                `#FFFFFF`,
                `#000000`
              )
            : color?.hex,
      }}
      {...other}
    >
      {text}
    </Button>
  );
};

export default SanityButton;
